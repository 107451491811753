





















import {Component, Prop, Vue} from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

@Component({})
export default class BaseDataCellComponent extends Vue {

  @Prop()
  public cellTitle!: VueI18n.TranslateResult | string;

  @Prop()
  public cellText!: VueI18n.TranslateResult | string;

  // the checks if string is a REAL Date, because the isValid function of moment is not enough
  public isNumber(attribute: any): boolean {
    return Number.isInteger(+attribute);
  }

}
