












































































































































































































import {Component, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import Throttle from '@/helper/Throttle';
import UserTimeTrackingComponent from '@/components/user/UserTimeTracking.component.vue';
import TimeTrackingFilterComponent from '@/components/time-tracking/TimeTrackingFilter.component.vue';
import UserBaseDataComponent from '@/components/user/UserBaseData.component.vue';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import moment, {Moment} from 'moment';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import WorkSession from '@/models/WorkSession';
import UserArchiveComponent from '@/components/user/UserArchive.component.vue';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {TabItem} from '@/interfaces/TabItem';
import {Permission} from '@/misc/Enums/permission.enum';
import {userStoreActions, userStoreGetter, userStoreMutations} from '@/stores/user.store';
import {jobOccurrenceStoreActions} from '@/stores/jobOccurrence.store';

const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const JobOccurrenceStore = namespace('jobOccurrence');

@Component({
  components: {
    RJTextField,
    RJTabs,
    UserArchiveComponent,
    UserTimeTrackingComponent,
    TimeTrackingFilterComponent,
    UserBaseDataComponent,
    UserManageComponent: () => import(
        /* webpackChunkName: "UserManageComponent" */
        '@/components/user/UserManage.component.vue'),
  },
})
export default class UserDetailsView extends Vue {

  @UserStore.Action(userStoreActions.LOAD_USER_ACTION)
  private loadUserAction!: (userId: string) => Promise<User>;
  @UserStore.Action(userStoreActions.RESEND_INVITATION_ACTION)
  private resendInvitationAction!: (user: User) => Promise<void>;
  @UserStore.Getter(userStoreGetter.ACTIVE_USER)
  private _user!: User;
  @UserStore.Mutation(userStoreMutations.STORE_ACTIVE_USER)
  private storeActiveUser!: (user: User | null) => void;
  @UserStore.Action(userStoreActions.DELETE_USER_ACTION)
  public deleteUserAction!: (user: User) => Promise<User>;
  @UserStore.Action(userStoreActions.LOAD_USERS_ACTION)
  private loadUsersAction!: (payload: { tenantId: string }) => Promise<User[]>;
  @UserStore.Action(userStoreActions.EDIT_USER_ACTION)
  private editUserAction!: (user: User) => Promise<User>;
  @UserStore.Action(userStoreActions.DOWNLOAD_TIMESHEET)
  private downloadTimesheet!: (payload: {user: User, date: string}) => Promise<any>;

  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.LOAD_WORK_SESSIONS_ACTION)
  public getWorkSessionsAction!: (filterSettings: WorkSessionFilterData) => Promise<WorkSession[]>;

  get user(): User {
    return this._user;
  }

  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  /**
   * visual values
   */
  private throttle!: Throttle;
  private THROTTLE_DELAY: number = 500;
  public tabsModel: number = 0;
  public showManageUserSideCard = false;
  public showDeleteUserDialog = false;
  private showOTPDialog: boolean = false;
  public today: string = moment().toISOString();


  /**
   * filter values
   */
  private dateStartPickerValue: string = '';
  private dateEndPickerValue: string = '';
  private filterHasComment: boolean = false;
  private filterHasImages: boolean = false;
  private customers: Customer[] | null = [];
  private currentFilter!: WorkSessionFilterData;
  public tenantId!: string;
  public currentTimeFilter: string = 'month';
  // string for the OTP generation
  public oneTimePassword: string = '';
  /**
   * Current Date selected date, default is today's date.
   */
  public currentDate!: Moment;
  /**
   * Current Week, default is this week of the year
   */
  public currentWeek: number = 0;
  /**
   * Current year, default this year
   */
  public currentYear: number = 0;
  /**
   * Current Month, default is the this month of the year
   */
  public currentMonth: number = 0;
  /**
   * Max weeks of current year
   */
  public maxWeeks!: number;
  /**
   * All available Customer Locations
   */
  public locations: Location[] = [];
  /**
   * Current Selected Locations
   * in the filter settings
   */
  public currentObjects: Location[] | null = [];

  /**
   * The month to download. Set to the month before, because normally no user wants to know the times of this month,
   * but the month before
   * @private
   */
  private pickerValue: string = moment().subtract(1, 'month').format('YYYY-MM');


  public async mounted() {

    try {
      // reset activeUser store entry to avoid wrong user loading bug
      this.storeActiveUser(null);
      // load all data that is required for this view;
      await this.loadUserAction(this.$route.params.userId);
      this.throttle = new Throttle(this.THROTTLE_DELAY);
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }

    this.currentFilter = new WorkSessionFilterData();
    this.currentFilter.tenant = this.$route.params.tenantId;
    this.currentFilter.createdAtFrom = moment().startOf('month').toISOString();
    this.currentFilter.createdAtTo = moment().endOf('month').toISOString();
    this.currentFilter.users = [this.user.id!];
    // Load all necessary relations like customers, users and object locations
  }

  private createPassword() {
    this.showOTPDialog = true;
  }

  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'basedata',
        text: this.$t('USER_DETAIL.TABS.BASE_DATA').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      },
        /*
        //todo reimplement this when Archive and Usertimetracking is ready
         {
        key: 'timetracking',
        text: this.$t('GENERAL.TIME_TRACKING').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.WORK_SESSION_READ_OWN),
      }, {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_UPDATE_OWN),
      },
         */
    ];
  }

  /**
   * Resend the invitation link
   */
  public async resendInvitation() {
    this.throttle.throttle(async () => {
      try {
        await this.resendInvitationAction(this.user!);
        this.$notifySuccessSimplified('USER_DETAIL.NOTIFICATIONS.INVITATION_RESEND.SUCCESS');
      } catch (e) {
        if (e.status === 429) { // too error code for many mails were sent
          e.data.resendAllowedAt = this.$options.filters!.toDateTime(e.data.resendAllowedAt); // format date
          this.$notifyErrorSimplified(`USER_DETAIL.NOTIFICATIONS.INVITATION_RESEND.${e.status}`, e.data, 10000);
        } else {
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      }
    });
  }

  /**
   * create a new OTP for a user without an accepted invite
   */
  public async replacePassword() {
    this.oneTimePassword = Math.random().toString(36).substr(2, 8);
    try {
      const userCopy = User.parseFromObject(this.user.parseToObject());
      userCopy.oneTimePassword = this.oneTimePassword;
      // delete timeSchedules. They should not be updated. They cause an error anyway if filled with values
      await this.editUserAction(userCopy);
      this.showOTPDialog = false;
      this.$notifySuccessSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.OTP_SUCCESS');
    } catch (e) {
      this.$notifySuccessSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.OTP_ERROR');
    }
  }

  public onEditUserClick() {
    this.showManageUserSideCard = true;
  }

  public onDeleteUserClick() {
    this.showDeleteUserDialog = true;
  }

  public async onUserDelete() {
    this.showDeleteUserDialog = false;
    try {
      await this.deleteUserAction(this.user);
      this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.SUCCESS');
      // if successful
      await this.$router.push({
        name: 'usersOverview', params: {
          tenantId: this.$route.params.tenantId,
        },
      });
    } catch (e) {
      this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.ERROR');
    }
  }

  private getTabSize(): number {
    if (this.tabsModel === 1) {
      return 10;
    } else {
      return 12;
    }
  }

  private onTimeFilterUpdated(timeFilter: string) {
    this.currentTimeFilter = timeFilter;

    // Reset Values if TimeFilter was changed
    const today = moment();
    this.currentDate = today;
    this.currentYear = today.year();
    this.maxWeeks = today.isoWeeksInYear();
    this.currentWeek = today.week();
    this.currentMonth = today.month() + 1;
  }

  public getCurrentFilterSettings(): WorkSessionFilterData {

    // Build Filter Object
    const filter = {...this.currentFilter};

    // Set Customers to Filter
    filter.customers = this.customers && this.customers.length > 0
        ? this.customers!.map((customer) => customer.id!)
        : undefined;

    // Set Locations to Filter
    filter.locations = this.currentObjects && this.currentObjects.length > 0
        ? this.currentObjects!.map((location) => location.id!)
        : undefined;

    // individual time frame
    if (this.isIndividual) {
      const fromDate = moment.utc(this.dateStartPickerValue);
      filter.createdAtFrom = fromDate.startOf('day').toISOString();

      // Check if End Date was Set
      // if not use today's date
      const toDate = this.dateEndPickerValue.length > 0
          ? moment.utc(this.dateEndPickerValue)
          : moment();

      // Set Created To Date
      filter.createdAtTo = toDate.endOf('day').local().toISOString();
    }

    // Selected day as Timeframe
    if (this.isDaily) {
      const startOfDay = moment(this.currentDate).startOf('day');
      const endOfDay = moment(this.currentDate).endOf('day');
      filter.createdAtFrom = startOfDay.toISOString();
      filter.createdAtTo = endOfDay.toISOString();
    }

    // Selected Month as Timeframe
    if (this.isMonthly) {
      // Get Current Week
      const month = moment().set('month', this.currentMonth - 1).set('year', this.currentYear);

      // Get start and end of Week
      const startOfMonth = moment(month).startOf('month');
      const endOfMonth = moment(month).endOf('month');

      // Set From and To for current filter
      filter.createdAtFrom = startOfMonth.toISOString();
      filter.createdAtTo = endOfMonth.toISOString();
    }

    // Selected Week as Timeframe
    if (this.isWeekly) {
      // Get Current Week
      const week = moment().set('year', this.currentYear).set('week', this.currentWeek);

      // Get start and end of Week
      const startOfWeek = moment(week).startOf('week');
      const endOfWeek = moment(week).endOf('week');

      // Set From and To for current filter
      filter.createdAtFrom = startOfWeek.toISOString();
      filter.createdAtTo = endOfWeek.toISOString();
    }

    filter.hasComments = this.filterHasComment;
    filter.hasImages = this.filterHasImages;

    return filter;
  }

  /**
   * Listener for Date Value Changes
   * sets the end and start picker values
   * e.g. for displaying at the top
   */
  public onDateValuesChanged(dates: { dateStart: string, dateEnd: string }) {
    this.dateEndPickerValue = dates.dateEnd;
    this.dateStartPickerValue = dates.dateStart;
  }

  /**
   * Event Listener for Misc Boolean changes
   */
  private onMiscUpdated(data: { hasComments: boolean, hasImages: boolean }) {
    this.filterHasImages = data.hasImages;
    this.filterHasComment = data.hasComments;
  }

  /**
   * Gets executed if the clear button
   * for a customer was pressed
   */
  public onCustomerClearClicked() {
    // Reset locations and current selected object
    this.customers = [];
    this.locations = [];
    this.currentObjects = [];
  }

  public onObjectChanged(objects: Location[]) {
    this.currentObjects = objects;
  }

  /**
   * Checks if Filter is set to Individual
   */
  private get isIndividual(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'individual';
  }

  /**
   * Checks if Filter is set to Weekly
   */
  private get isWeekly(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'week';
  }

  /**
   * Checks if Filter is set to Daily
   */
  private get isDaily(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'day';
  }

  /**
   * Checks if Filter is set to Monthly
   */
  private get isMonthly(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'month';
  }

  public async download() {
    await this.downloadTimesheet({
      date: moment(this.pickerValue).format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString()),
      user: this.user!,
    });
  }
}
