

















































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import BaseDataCellComponent from '@/components/shared/BaseDataCell.component.vue';

const UserStore = namespace('user');

@Component({
  components: {BaseDataCellComponent},
})
export default class UserBaseDataComponent extends Vue {

  @UserStore.Getter('activeUser')
  private _user!: User;
  @UserStore.Action('setUserStatusAction')
  private setUserStatusAction!: (payload: { id: string, active: boolean, preview?: boolean, force?: boolean }) => Promise<User>;

  get user(): User {
    return this._user;
  }

  public showChangeStatusDialog = false;
  public showDeactivationDialog = false;
  private personalInfoActive: boolean = false;

  public onStatusChange() {
    this.showChangeStatusDialog = false;
    if (this.user.active === true) {
      this.showDeactivationDialog = true;
    } else {
      this.changeUserStatus();
    }
  }

  public async changeUserStatus() {
    this.showDeactivationDialog = false;
    try {
      const params = {
        id: this.user.id!,
        active: !this.user.active!,
        force: true,
      };
      await this.setUserStatusAction(params);

      this.user.active ?
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DEACTIVATE') :
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_ACTIVATE');
    } catch (error) {
      // Reset Activate State to False, because some error happened
      this.user.active = false;

      // Show Correct error Message
      if (error.status === 402) {
        this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.QUOTA_EXCEEDED');
      } else {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    }
  }

  public getCommentData() {
    return {
      date: this.$options.filters!.toDate(this.user.createdAt),
      time: this.$options.filters!.toZoneTime(this.user.createdAt),
    };
  }
}
